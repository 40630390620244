import * as React from "react";
import { Helmet } from "react-helmet";
import { globalHistory } from "@reach/router";

import Template from "../components/Template";

import * as styles from "./contact.module.scss";

const ContactPage = ({ location }) => {
  const pageLang = !globalHistory.location.pathname.includes("/fr")
    ? "en"
    : "fr";

  const show = (lang) => {
    if (lang === "en" && pageLang === "en") return { display: "block" };
    else if (lang === "fr" && pageLang === "fr") return { display: "block" };
    else return { display: "none" };
  };

  return (
    <Template location={location}>
      <Helmet>
        <title>{`Charih / ${pageLang === "en" ? "Contact" : "Contact"}`}</title>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
      </Helmet>
      <div className={styles.wrapper}>
        <h1 style={show("en")}>Contact me!</h1>
        <h1 style={show("fr")}>Contactez-moi!</h1>
        <img src="/coffee_cup.png" className={styles.coffeeImage} />
        <p className={styles.paragraph} style={show("en")}>
          Do not hesitate to get in touch! I'm always happy to discussion
          potential collaboration opportunities, especially if it is done over a
          great cup of coffee!
        </p>
        <p className={styles.paragraph} style={show("fr")}>
          N'hésitez pas à entrer en contact avec moi. Je suis constamment à la
          recherche de collaborateurs potentiels et suis toujours disposé à
          discuter avec vous, particulièrement en appréciant un bon café.
        </p>
        <p className={styles.paragraph} style={show("en")}>
          Click below to download my vCard and add me to your contacts:
        </p>
        <p className={styles.paragraph} style={show("fr")}>
          Cliquez sur l'icône ci-dessous pour télécharger ma carte virtuelle et
          m'ajouter à vos contacts:
        </p>
        <div className={styles.iconWrapper}>
          <a href="/charih.vcf">
            <i
              class="fa fa-address-card-o fa-5x"
              className={styles.vCardIcon}
              aria-hidden="true"
              //style={{ fontSize: 48, margin: "20 px" }}
            ></i>
          </a>
        </div>
      </div>
    </Template>
  );
};

export default ContactPage;
